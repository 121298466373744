















import {
  defineComponent, computed, watch, onMounted,
} from '@vue/composition-api';
import ApexCharts from 'apexcharts';
import merge from 'lodash/merge';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const id = computed(() => props.data.id);
    const title = computed(() => props.data.title);

    const chartData = computed(() => merge({
      colors: [
        '#2F4858',
        '#FF3089',
        '#008F7A',
        '#0089BA',
        '#2C73D2',
        '#F9F871',
        '#FFC75F',
        '#FF9671',
        '#FF6F91',
        '#D65DB1',
        '#C2791E',
        '#874800',
        '#845EC2',
        '#00F8FF',
        '#6B9800',
        '#55003D',
        '#948D00',
        '#9B0016',
        '#FF8443',
        '#574240',
      ],
      chart: {
        type: 'rangeBar',
        height: 'auto',
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        showForZeroSeries: false,
        showForNullSeries: false,
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: true,
        fixed: {
          enabled: true,
        },
      },
      yaxis: {
        showForNullSeries: true,
        tickAmount: 10,
        forceNiceScale: true,
        title: {
          rotate: 270,
          // offsetX: 40,
          // offsetY: -190,
        },
      },
      xaxis: {
        labels: {
          hideOverlappingLabels: false,
        },
      },
    },
    props.data.chartConfigs));

    onMounted(() => {
      const chart = new ApexCharts(document.getElementById(id.value), chartData.value);
      chart.render();
      watch(chartData, val => {
        chart.updateOptions(val);
      });
    });

    return {
      id,
      title,
      chartData,
    };
  },
});
